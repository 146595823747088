import React from "react"
import { Link } from "gatsby"

import { getUrlPath } from "utils"
import useMenuItems from "./useMenuItems"

const HeaderSecondaryMenu = (props) => {
  const items = useMenuItems("header-secondary-menu")

  return (
    <div className="flex justify-center lg:justify-end" {...props}>
      {items &&
        items.map(({ id, url, target, label }) => (
            <div className="flex border-l-2 first:border-l-0 border-ca-gray-800 relative" key={id}>
              <Link
                as={target === "_blank" ? "a" : Link}
                to={getUrlPath(url, target)}
                href={getUrlPath(url, target)}
                target={target !== null && target ? target : "false"}
                className="text-white px-3 lg:px-4 py-2 text-xs lg:text-sm before:-z-[1] before:opacity-0 before:inset-0 before:bg-ca-red-500 before:scale-x-[0.3] before:w-full before:h-full before:absolute hover:before:opacity-100 hover:before:transition hover:before:duration-700 hover:before:ease-[cubic-bezier(0.08, 0.35, 0.13, 1.02)] hover:before:bg-ca-red-500 hover:before:scale-x-100"
              >
                {label}
              </Link>
            </div>
        ))}
    </div>
  )
}
export default HeaderSecondaryMenu
