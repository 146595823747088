import React, { createElement, useEffect, useRef } from "react"
import { Widget } from "@typeform/embed-react"
import Parser from "html-react-parser"
import Helmet from "react-helmet"

const DangerouslySetHtmlContent = ({
                                     html,
                                     dangerouslySetInnerHTML,
    allowRerender,
    ...rest
  }) => {
  // We remove 'dangerouslySetInnerHTML' from props passed to the div
  const divRef = useRef(null)
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (!html || !divRef.current) throw new Error("html prop can't be null")
    if (!isFirstRender.current) return
    isFirstRender.current = Boolean(allowRerender)

    const slotHtml = document.createRange().createContextualFragment(html) // Create a 'tiny' document and parse the html string
    divRef.current.innerHTML = '' // Clear the container
    divRef.current.appendChild(slotHtml) // Append the new content
  }, [html, divRef])

  return createElement('div', { ...rest, ref: divRef })
}

const Form = (props) => {
  const {
    buttonText,
    formId,
    formEmbedCode,
    hideTitle,
    hiddenFields,
    formType,
    hidden,
    ...rest
  } = props
  const divRef = useRef(null)
  const isFirstRender = useRef(true)

  const initForm = () => {
    if ('hbspt' in window) {
      const html = `
            <script>window.hbspt.forms.create({region: "na1", portalId: "45842344", formId: "${formId}"});</script>
          `

      const slotHtml = document.createRange().createContextualFragment(html) // Create a 'tiny' document and parse the html string
      divRef.current.innerHTML = '' // Clear the container
      divRef.current.appendChild(slotHtml)
    } else {
      setTimeout(initForm, 250)
    }
  }

  useEffect(() => {
    let timeoutId = 0
    if (formType === "hubspot") {
      if (!isFirstRender.current) return
      isFirstRender.current = Boolean(false)
      const script = document.createElement('script')
      script.setAttribute('src', 'https://js.hsforms.net/forms/embed/45842344.js')
      document.body.appendChild(script);
    }
    if (formType === "hubspot-legacy") {
      if (!isFirstRender.current) return
      isFirstRender.current = Boolean(false)

      const script = document.createElement('script')
      script.setAttribute('src', 'https://js.hsforms.net/forms/embed/v2.js')
      document.body.appendChild(script);

      initForm()
    }
  }, [formType, formId]);

  return (
    <div {...rest}>
      {
        (formType === "hubspot") ? (<div className={`w-full`}>
              <div className="hs-form-frame" data-region="na1" data-form-id={formId} data-portal-id="45842344"></div>
            </div>
          ) :
          (formType === "hubspot-legacy") ? (<div className={`w-full`} ref={divRef}>
              </div>
            ) :
            (formType === "typeForm") ? (
                <Widget
                  id={formId}
                  hideFooter={true}
                hideHeaders={true}
              opacity={100}
              inlineOnMobile={true}
              style={{
                height: "calc(100vh - 380px)",
                minHeight: "500px",
                width: "100%"
              }}
            />
          ) :
           (formType === "airslate") ? Parser(formEmbedCode) :
             (<p>Form type not found.</p>)
      }
    </div>
  )
}

export default Form
