import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import Parser from "html-react-parser"
import { Typography } from "@material-ui/core"

// import app components
import { BackgroundImage, Edges } from "components"

import * as theme from "theme"
import { getUrlPath, getIcon } from "utils"
import useMenuItems from "./useMenuItems"
import ChevronDown from "icons/chevron-down.svg"
import ArrowRightIcon from "icons/arrow-right.svg"
import MenuIndentIcon from "icons/menu-indent-right.svg"

const DesktopMenu = (props) => {
  const items = useMenuItems("desktop-main-menu")

  const [pointer, setPointer] = useState(true)

  const handleSubmenuClick = () => {
    setPointer(false)
  }

  useEffect(() => {
    setTimeout(() => setPointer(true), 100)
  }, [pointer])

  return (
    <Menu {...props}>
      {items &&
        items.map((menuItem) => {
          const { url, target, label, childItems, acfMenu, databaseId } = menuItem

          const showHero =
            acfMenu?.megaNav?.heroImage?.image &&
            acfMenu?.megaNav?.heroImage?.link?.uri

          return (
            <MenuItem key={`desktop-menu-item-${databaseId}`} pointer={pointer}>
              {childItems && childItems.nodes.length ? (
                <>
                  {url === "#" ? (
                    <MenuLinkContainer>
                      <Typography
                        color="inherit"
                        component="div"
                        variant="subtitle1"
                        children={Parser(label)}
                      />

                      <ChevronDown />
                    </MenuLinkContainer>
                  ) : (
                    <MenuLink
                      as={target === "_blank" ? "a" : Link}
                      to={getUrlPath(url, target)}
                      href={getUrlPath(url, target)}
                      target={target !== null && target ? target : undefined}
                    >
                      <Typography
                        color="inherit"
                        component="div"
                        variant="subtitle1"
                        children={Parser(label)}
                      />
                      <ChevronDown />
                    </MenuLink>
                  )}

                  <SubMenu className="sub-menu">
                    <Edges size="lg">
                      <SubMenuInner>
                        <SubMenuItems height={acfMenu?.megaNav?.height}>
                          {childItems.nodes.map((o, i) => {
                            const hasChildren =
                              !!o?.childItems?.nodes &&
                              o.childItems.nodes.length > 0

                            return (
                              <React.Fragment key={`desktop-menu-item-${databaseId}-${i}`}>
                                <SubMenuLink
                                  as={o.target === "_blank" ? "a" : null}
                                  target={o.target !== null && o.target ? o.target : undefined}
                                  to={getUrlPath(o.url, o.target)}
                                  href={getUrlPath(o.url, o.target)}
                                  activeClassName="active"
                                  columns={acfMenu.megaNav.columns}
                                  height={acfMenu?.megaNav?.height}
                                  hasChildren={hasChildren}
                                  onClick={handleSubmenuClick}
                                >
                                  <div>
                                    {o?.acfMenu?.item?.icon && (
                                      <IconContainer>
                                        {getIcon(o.acfMenu.item.icon)}
                                      </IconContainer>
                                    )}

                                    <SubMenuLinkTitle
                                      color="inherit"
                                      component="div"
                                    >
                                      {Parser(o.label)}

                                      {acfMenu?.megaNav?.height === "auto" && (
                                        <ArrowRightIcon />
                                      )}
                                    </SubMenuLinkTitle>

                                    {o?.acfMenu?.item?.text && (
                                      <Description
                                        color="inherit"
                                        component="div"
                                        variant="body2"
                                        children={Parser(o.acfMenu.item.text)}
                                      />
                                    )}
                                  </div>
                                </SubMenuLink>

                                {o.childItems &&
                                  o.childItems.nodes.map((p, j) => {
                                    return (
                                      <React.Fragment key={`desktop-menu-item-${databaseId}-${i}-${j}`}>
                                        <SubSubMenuLink
                                          as={target === "_blank" ? "a" : Link}
                                          to={getUrlPath(p.url, p.target)}
                                          href={getUrlPath(p.url, p.target)}
                                          target={p.target !== null && p.target ? p.target : undefined}
                                          activeClassName="active"
                                          onClick={handleSubmenuClick}
                                        >
                                          <Typography
                                            color="inherit"
                                            component="div"
                                            children={Parser(p.label)}
                                          />
                                        </SubSubMenuLink>
                                        {p.childItems &&
                                          p.childItems.nodes.map((q, k) => {
                                            return (
                                              <SubSubMenuLink
                                                key={`desktop-menu-item-${databaseId}-${i}-${j}-${k}`}
                                                as={
                                                  target === "_blank"
                                                    ? "a"
                                                    : null
                                                }
                                                to={getUrlPath(q.url, q.target)}
                                                href={getUrlPath(
                                                  q.url,
                                                  q.target
                                                )}
                                                target={
                                                  q.target !== null && q.target ? q.target : undefined
                                                }
                                                activeClassName="active"
                                                onClick={handleSubmenuClick}
                                              >
                                                <MenuIndentIcon className="small-icon" />
                                                <Typography
                                                  color="inherit"
                                                  component="div"
                                                  children={Parser(q.label)}
                                                />
                                              </SubSubMenuLink>
                                            )
                                          })}
                                      </React.Fragment>
                                    )
                                  })}
                              </React.Fragment>
                            )
                          })}
                        </SubMenuItems>

                        {showHero && (
                          <SubMenuImageColumn>
                            <SubMenuImage
                              to={getUrlPath(
                                acfMenu.megaNav.heroImage.link.uri
                              )}
                              onClick={handleSubmenuClick}
                            >
                              <BackgroundImage
                                image={{
                                  image: acfMenu.megaNav.heroImage.image,
                                  size: "cover"
                                }}
                                alt={acfMenu?.megaNav?.heroImage?.text || ""}
                              />

                              {acfMenu?.megaNav?.heroImage?.text && (
                                <SubMenuImageText>
                                  {acfMenu.megaNav.heroImage.text}
                                  <ArrowRightIcon />
                                </SubMenuImageText>
                              )}
                            </SubMenuImage>
                          </SubMenuImageColumn>
                        )}
                      </SubMenuInner>
                    </Edges>
                  </SubMenu>
                </>
              ) : (
                <MenuLink
                  as={target === "_blank" ? "a" : Link}
                  to={getUrlPath(url, target)}
                  href={getUrlPath(url, target)}
                  target={target !== null && target ? target : undefined}
                >
                  <Typography
                    color="inherit"
                    component="div"
                    variant="subtitle1"
                    children={Parser(label)}
                  />
                </MenuLink>
              )}
            </MenuItem>
          )
        })}
    </Menu>
  )
}

export default DesktopMenu

const Menu = styled.div`
  display: flex;

  a {
    text-decoration: none;
  }
`
const MenuItem = styled.div`
  display: flex;
  border-left: 1px solid ${theme.colors.primary};
  border-right: 1px solid ${theme.colors.primary};

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }

  ${({ pointer }) =>
    pointer &&
    css`
      &:hover {
        .sub-menu {
          opacity: 1;
          pointer-events: all;
        }
      }
    `}

  &:hover {
    a:before {
      opacity: 1;
      background-color: ${theme.colors.secondary};
      transform: scaleX(1);
      transition: transform 0.6s cubic-bezier(0.08, 0.35, 0.13, 1.02), opacity;
    }
  }
  .sub-menu {
    opacity: 0;
    pointer-events: none;
  }
`

export const MenuLinkStyle = css`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  color: #fff;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: ${theme.colors.secondary};
    transform: scaleX(0.3);
    opacity: 0;
    transition: all 0.3s;
  }
`

export const MenuLink = styled(Link)`
  ${MenuLinkStyle}
`

const SubMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  background: ${theme.colors.primary};

  &:hover {
    ~ a {
      &:before {
        opacity: 1;
        background-color: ${theme.colors.secondary};
        transform: scaleX(1);
        transition: transform 0.6s cubic-bezier(0.08, 0.35, 0.13, 1.02), opacity;
      }
    }
  }
`

const SubMenuInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 4px 4px 0;
`

const SubMenuItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1;
  flex-direction: ${({ height }) => (height === "auto" ? "column" : "row")};
  max-height: 565px;
`

const IconContainer = styled.div`
  margin-bottom: 15px;

  svg {
    height: 30px;
  }
`

const Description = styled(Typography)`
  && {
    color: #f3f6ffba;
  }
`

const SubMenuImageColumn = styled.div`
  padding: 15px;
  flex-shrink: 0;
  height: 340px;
  width: 300px;
`

const SubMenuImage = styled(Link)`
  display: flex;
  position: relative;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
`

const SubMenuImageText = styled(Typography)`
  && {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px 30px 15px 15px;
    color: #fff;
    background: rgba(28, 34, 42, 0.46);
    backdrop-filter: blur(5px);

    svg {
      position: absolute;
      z-index: 2;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`

const SubMenuLink = styled(({ hasChildren, ...rest }) => <Link {...rest} />)`
  display: flex;
  width: calc(100% / ${({ columns }) => columns || 1} - 2px);
  height: ${({ height }) =>
    height === "full" ? "340px" : height === "half" ? "168px" : "auto"};
  margin-bottom: 4px;
  padding: ${({ hasChildren, height }) =>
    hasChildren || height === "auto" ? "15px 20px" : "30px 15px 15px"};
  background: ${theme.colors.background.dark};
  white-space: pre-wrap;
  color: #fff;

  &:hover,
  &.active {
    background: ${theme.colors.secondary};

    svg {
      path {
        fill: #fff;
      }
    }
  }

  > div {
    flex: 1;
  }
`

const SubMenuLinkTitle = styled(Typography)`
  && {
    position: relative;
    line-height: 1.3;
    font-size: 18px;

    svg {
      position: absolute;
      z-index: 2;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`

const SubSubMenuLink = styled(Link)`
  && {
    display: flex;
    padding: 5px 20px;
    color: #fff;

    &:hover,
    &.active {
      color: ${theme.colors.secondary};
    }

    > div {
      font-size: 14px;
    }
  }
  .small-icon {
    color: #2c3745;
  }
`

const MenuLinkContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 10px;
  margin-left: 20px;
  color: #fff;
  cursor: pointer;

  svg {
    margin-left: 5px;
  }
`
