import { useStaticQuery, graphql } from "gatsby"

export const useMenuItems = (slug) => {
  const {
    allWpMenu: { menus }
  } = useStaticQuery(graphql`
    {
      allWpMenu {
        menus: nodes {
          id
          slug
          menuItems {
            nodes {
              parentDatabaseId
              id
              databaseId
              label
              url
              target
              cssClasses
              acfMenu {
                megaNav {
                  columns
                  height
                  heroImage {
                    link {
                      title
                      uri: url
                      target
                    }
                    text
                    image {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(width: 400, layout: CONSTRAINED)
                        }
                      }
                    }
                  }
                }
                level
              }
              childItems {
                nodes {
                  id
                  label
                  url
                  target
                  cssClasses
                  acfMenu {
                    item {
                      icon
                      text
                    }
                    level
                  }
                  childItems {
                    nodes {
                      id
                      label
                      url
                      target
                      cssClasses
                      childItems {
                        nodes {
                          id
                          label
                          url
                          target
                          cssClasses
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const menu = !!menus && menus.filter((menu) => menu.slug === slug)[0]

  const menuItems =
    menu?.menuItems?.nodes &&
    menu.menuItems.nodes.filter((item) => item.parentDatabaseId === 0)

  return menuItems
}

export default useMenuItems
